.component__hero {
  height: 600px;
  text-align: center;
  background-color: lightgrey;
  // @include breakpoint($tablet-md) {
  //   height: 100%;
  // }
  &--container {

  }
  &--row {

  }
  &--col {
    &.col-left {
      height: 600px;
      background-image: url("../../img/hero-bg.jpg");
      background-position: center center;
      padding-left: 0px;
      padding-right: 0px;
      .hero-overlay {
        height: 100%;
        color: white;
        text-align: left;
        background-color: transparentize($brand-blue, .2);
        display: flex;
        .hero-copy-container {
          align-self: center;
          .hero-header {
            font-size: 46px;
            font-weight: 700;
            @include breakpoint($phone-lg) {
              font-size: 32px;
            }
          }
          .text-decorate {
            border-bottom: 6px solid white;
            width: 28%;
            float: left;
          }
          .hero-subcopy {
            color: white;
            font-size: 16px;
            font-weight: 500;
            margin-top: 50px;
          }
        }
        @extend .section-padding;
      }
    }
    &.col-right {
      background-color: white;
      background-image: url("../../img/hero-sidebar-bg.png");
      background-repeat: no-repeat;
      background-position-x: -114%;
      background-position-y: -37%;
      background-size: 80%;
      .hero-sidebar-copy-container {
        margin-top: 60px;
        .hero-sidebar-header {
          color: $brand-blue;
          font-size: 36px;
          font-weight: 700;
        }
        .text-decorate-sidebar {
          border-bottom: 4px solid $brand-green;
          width: 12%;
        }
        .hero-sidebar-button-container {
          display: block;
          margin-top: 60px;
          .btn-primary,
          .btn-tertiary {
            width: 100%;
            margin: 20px 0;
            box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
          }
        }
      }
      @extend .section-padding;
    }
  }
}