// **colors**
$brand-blue: #05386b;
$brand-green: #5cdb95;
$light-grey: #f5f5f5;
$mid-grey: #717171;
$dark-grey: #898989;

// **breakpoints**
// phone
$phone-sm: 320px;
$phone-sm-landscape: 568px;
$phone-md: 375px;
$phone-md-landscape: 812px;
$phone-lg: 414px;
$phone-lg-landscape: 736px;
$phone-xl-landscape: 812px;
// tablet
$tablet-sm: 768px;
$tablet-sm-landscape: 1024px;
$tablet-md: 992px;
$tablet-lg: 1024px;
$tablet-lg-landscape: 1366px;