.navbar {
  border-bottom: 1.5px solid $brand-green;
  height: 90px;
  .navbar-brand {
    &:focus {
      color: rgba(0,0,0,0);
    }
    .nav-logo {
      width: 300px;
      padding: 10px;
      @include breakpoint-min($tablet-lg-landscape) {
        margin-left: 50px;
      }
      @include breakpoint($tablet-sm) {
        width: 120px;
      }
      @include breakpoint($phone-lg) {
        width: 224px;
      }
    }
  }
  .navbar-toggler {
    border-color: rgba(0,0,0,0);
  }
  .navbar-collapse {
    // change on mobile
    margin-right: -100px;
    .navbar-nav {
      margin-left: auto;
      @include breakpoint-min($tablet-lg-landscape) {
        margin-right: 50px;
      }
      @include breakpoint($tablet-sm) {
        text-align: center;
        padding: 18px 0;
      }
      .nav-item {
        .nav-link {
          color: black;
          font-weight: 700;
          text-transform: uppercase;
          padding: 6px 14px;
          
        }
        &.nav-btn {
          .btn-primary--dark {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  button {
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12) !important;
  }
  balance-tab {
    width: 100px;
    background-color: blue;
    z-index: 1000000;
    position: fixed;
    margin-top: 90px;
    float: right;
  }
}

.balance-tab {
  color: white;
  background-color: $brand-green;
  font-size: 16px;
  text-transform: uppercase;
  z-index: 1000000;
  padding: 8px 18px;
  border-bottom-left-radius: 20px;
  margin-top: 136px;
  margin-right: -16px;
}
